<template>
  <div class="info">
    <top-bar :title="'社区码'" :left="true"></top-bar>
    <div class="head">
      <span class="head-text">居民扫此二维码进行登记</span>
      <van-image class="head-img" :src="require('../../assets/img/community_code_bottom.png')"/>
      <div class="code">
        <van-image :src="url" />
      </div>
    </div>

    <van-cell-group class="info-second list-info">
      <van-cell class="info-title">
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">扫码记录</span>
          <span class="custom-title" style="float: right" @click="selectMore">更多<van-icon name="arrow" /></span>
        </template>
      </van-cell>
      <div class="cont info-title">
        <van-list v-if="dataList.length > 0" offset="10">
          <div class="list-item" v-for="item in dataList" :key="item.id">
            <div class="content">
              <div class="content-text">
                <div class="top-text">{{ item.userName }}</div>
                <div class="right-text" :style="{color:item.userId > 0?'#007aff':'#ff5e5e'}">{{item.userId > 0?'已登记':'未登记'}}</div>
                <div class="bottom-text">{{item.registerTime?'登记时间：'+item.registerTime:'扫描时间：'+item.createTime}}</div>
              </div>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </div>
    </van-cell-group>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { getImageStream } from "@/utils/index";
export default {
  components: {
    topBar,
  },
  data() {
    return {
      url: '',
      dataForm: {
        page: 1,
        limit: 5
      },
      dataList: []
    }
  },
  mounted() {
    this.$http({
      url: this.$http.adornUrl("/wxapp/getRegisterQrCode"),
      method: "post",
    }).then(({ data }) => {
      if (data.code == 0) {
        this.url = getImageStream(data.relativePath)
      }
    },
    (err) => {
      this.$toast.clear()
    })
    this.getDataList()
  },
  methods: {
    selectMore() {
      this.$router.push({ path: "/communityCode-list" });
    },
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      })
      this.$http({
        url: this.$http.adornUrl("/wxapp/registerLog/list"),
        method: "post",
        params: this.$http.adornParams(this.dataForm)
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataList = data.page.list
        }
        // 加载状态结束
        this.$toast.clear()
      },
      (err) => {
        this.$toast.clear()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  .head {
    height: 550px;
    width: 550px;
    margin: 0 auto;
    position: relative;
  }
  .head-text {
    margin: 0 auto;
    position: absolute;
    top: 45px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 35px;
    z-index: 1;
  }
  .code {
    height: 310px;
    width: 310px;
    margin: 0 auto;
    position: absolute;
    top: 170px;
    left: 0px;
    right: 0px;
    z-index: 1;
  }
  .head-img {
    height: 100%;
    width: 100%;
  }
}
.list-info {
  padding-bottom: 20px !important;
}
.list-item {
  border: 1px solid #eee;
}
.list-item:nth-child(2) {
  margin-top: 20px !important;
}
</style>
